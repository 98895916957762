<template>
    <v-app>

        <v-toolbar color="white" density="compact" flat >
            <v-toolbar-title>
                <a href="https://vladlin.ru/" target="_blank"> <img :src="`/favicon.ico`" height="24"> </a>
            </v-toolbar-title> 
                
            <v-spacer/>
            <v-btn v-if="showGf"  @click="home">Galois Field</v-btn>
            <v-btn v-if="showGen" @click="gen">Generation</v-btn>
        </v-toolbar>

        <div class="main-content">
            <router-view />
        </div>

    </v-app>
</template>

<script>

export default {
  data() {
    return {
      showGf: false,
      showGen: true,
    };
  },
  methods: {
    home() {
        this.showGf = false;
        this.showGen = true;
        this.$router.push('/')
    },
    full() {
        this.$router.push('/full')
    },
    gen() {
        this.showGf = true;
        this.showGen = false;
        this.$router.push('/gen')
    },
    crc() {
        this.$router.push('/crc')
    },    
  },

};
</script>
