<template>
  <v-content>
    <v-container fluid>
    <v-card :elevation=1 outlined> 
      <v-card-item>
        <v-chip-group>
          <v-spacer/>
          <v-text-field
            label="GF(p^n)"
            hint="(p^n) или (p)"
            v-model="state.qgf" 
            :error-messages="v$.qgf.$errors.map(e => e.$message)"
            @blur="v$.qgf.$touch"
            append-inner-icon="mdi-select"
            @click:append-inner="genvalid"
            @keyup.enter="genvalid"
          ></v-text-field>
          <v-spacer/>
        </v-chip-group>

        <v-chip-group>
          <v-text-field
            label="Определены кандидаты в порождающие полиномы"
            v-model="state.gg"
            readonly
          ></v-text-field>          
          &nbsp;&nbsp;&nbsp;
          <v-btn @click="sendToGF">Результат отправить в GF</v-btn>
        </v-chip-group>

      </v-card-item>
    </v-card>

    <div id="scroll-container">
      <div id="large-container">
        <div id="container"></div>
      </div>
    </div>

    </v-container>
  </v-content>
</template>

<style>

  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f0f0f0;
    background: #fcfcfc;
    height: 100%;
    overflow: auto;
  }

  #large-container {
    width: 3000px;
    height: 3000px;
    overflow: hidden;
  }

  #scroll-container {
    width: calc(100% - 22px);
    height: calc(100vh - 22px);
    overflow: auto;
    margin: 10px;
    border: 1px solid grey;
  }  

</style>

<script>
//#region import
import { 
  addLeadingZero,
  permutateWithRepetitions,
  bikToPolinom,
  found,
  isArraysEqual,
  polinomKtoBinSimple,
  phi,
  xori
 } from '../utils/common.js';
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators'; //
import Konva from 'konva';
import { mapActions } from "vuex";//, mapGetters 
var width = window.innerWidth;
var height = window.innerHeight;
var PADDING = 500;
var layer = '';
//#endregion import

export default {
data() {
  return { 
    configKonva: {
      width: width,
      height: height
    },
    gene: [],
    ar: [],
  };
},

setup () {
  const numregex= helpers.regex(/^(\d*\^\d*|\d)*$/)
  
  const state = reactive({ 
    qgf: '', gg: ''
  })
  const rules = {
    qgf: { required, numregex: helpers.withMessage('число^число или число', numregex) },
  }
  const v$ = useVuelidate(rules, state)
  return { state, v$ }
},

mounted() {
//#region Konva
  var stage = new Konva.Stage({
    container: 'container',
    width: window.innerWidth + PADDING * 2,
    height: window.innerHeight + PADDING * 2,//2
    draggable: true,
  });

  layer = new Konva.Layer();
  stage.add(layer);

  var scrollContainer = document.getElementById('scroll-container');

  function repositionStage() {
    var dx = scrollContainer.scrollLeft - PADDING;
    var dy = scrollContainer.scrollTop - PADDING;
    stage.container().style.transform =
      'translate(' + dx + 'px, ' + dy + 'px)';
    stage.x(-dx);
    stage.y(-dy);
  }

  scrollContainer.addEventListener('scroll', repositionStage);
  repositionStage();
//#endregion Konva
  this.state.qgf = '2^2';
  this.generation();
  //console.log('xor:', this.xoo('10', '1121', 3));
},

methods: {

  generation(){
    layer.removeChildren(); // обнулить канву
    this.gene.length=0; // очистить сгенерированные порожд. полиномы

    let p=1, n=1, k=1;
    if(this.state.qgf.includes('^')) {
      const arqgf= this.state.qgf.split('^');
      p = Number(arqgf[0]); 
      n = Number(arqgf[1]);
      k = Math.pow(p, n);
    } else {
      k = p = Number(this.state.qgf);
    }
    //console.log('k', k);

    const q = (k-1).toString(p).length; // кол-во знаков: '00' или '000'
    const nk = Number(k.toString().length);

    let arrbase=[]; // генерация массива элементов на основе p^n
    for(let i=0; i<k; i++) {
      if(i<p) {
        arrbase.push(addLeadingZero(i.toString(), q));
      } else {
        arrbase.push(addLeadingZero(i.toString(p), q));
      }
    }
    this.ar = arrbase;
    
    const deltay = 32, x=10;
    let y=60;
    this.renderTotient(x, y, 20, k, n); // 0 шаг
    y+=90;
    this.renderArrbase(x, y, 20, arrbase, k); // 1 шаг
    const ar = this.defineCandidate(p,n,nk,k);
    const arcand = ar[0];
    //console.log('artest', ar[2]);
    const arcandlength = arcand.length; 
    y+=90;
    this.renderCandidate(x, y, 20, arcand, deltay, n, ar[1], k); // 2 шаг
    y+=arcandlength*deltay+50;  
    this.renderTest(x, y, 20, deltay, ar[2], k, nk, p, arrbase); // 3 шаг
    this.renderParam(x, 10, 24);

    //this.state.gg = this.gene;//Заполнить визуальные параметры
    this.state.gg = this.gene.join(';  ');//Заполнить визуальные параметры
    //console.log('->', ra);
  },

  sendToGF(){
    if(this.gene) {
      //console.log('gene:', this.gene[0]);
      this.register({ 
        g: this.gene[0], // this.gene[0][1]
        q: this.state.qgf,
        a: this.ar
      });
      this.$router.push('/'); //открыть GF
    }
  },

  renderTest(x, y, fontSize, deltay, artest, k, nk, p, arrbase){

    const param = '3. Тестирование кандидатов: ' + artest.length + ' шт. : ' + artest;
    layer.add(this.addText(x, y, param, fontSize));
    let dy=40;
    for(let i=0; i<artest.length; i++) {
      // сделать отдельную ф для каждого теста 
      this.renderTestPolinom(x+30, y+dy, fontSize, deltay, artest[i], k, nk, p, arrbase);
      dy+=deltay*k+100;
    }
  }, 

  calcDeg(k, g, p, arrbase, nk){
    const polinom = 'x^'+k;
    const bin = polinomKtoBinSimple(polinom.toString());
    let r = addLeadingZero(bin.toString(), arrbase[0].length);
    if (!found(r, arrbase)) {
      r = xori(r.toString(), g.toString(), Number(p));
      r = addLeadingZero(r.toString(), arrbase[0].length)       
    }
    const b = bikToPolinom(r);
    return [addLeadingZero(k.toString(), nk), polinom, b, r];
  },

  renderTestPolinom(x, y, fontSize, deltay, g, k, nk, p, arrbase){
    // вычислить степень от каждого k, и взять xori 
    let argen=[], arcan=[];
    for(let i=1; i<k; i++) {
      const ra = this.calcDeg(i, g, p, arrbase, nk);
      argen.push(ra);
      arcan.push(ra[3]);
    }
    argen.push([-1, 'x^k', 'Polynom', 'Symbol']); //добавить заголовок
    argen.sort();
    arcan.push(arrbase[0]); // добавить нулевой
    arcan.sort(); 
    //console.log('arcan:', arcan);

    //сравнить базовый и кандидат
    const isEqual = isArraysEqual(arrbase, arcan);
    //const param = g + ' -> ' + (isEqual ? 'Успешно' : 'Неуспешно');
    const param = g + ': ' + bikToPolinom(g, p) + '  ->  ' + (isEqual ? 'Успешно' : 'Неуспешно');
    if(isEqual){
      //this.gene.push([g, bikToPolinom(g,p)]); // передавать только полиномы
      this.gene.push(bikToPolinom(g,p));
    }
    layer.add(this.addText(x, y, param, fontSize));

    x+=15;
    y+=25;
    const param1 = 'базовый:  ' + arrbase;
    layer.add(this.addText(x, y, param1, fontSize));

    y+=20;
    const param2 = 'кандидат: ' + arcan;
    layer.add(this.addText(x, y, param2, fontSize));

    x+=20;
    y+=15;
    const max = arrbase[arrbase.length - 1];
    let maxlength = bikToPolinom(max).length;
    if(maxlength < 7 ) maxlength = 7;
    const deltax = maxlength * 13;  
    let line = 1, fill = 'black';
    argen.forEach((subjects) => {
      let num = 1;
      subjects.forEach((data) => {
        if (data == -1) data = 'k';
        this.draw(x, y, num, line, data, deltax, deltay, fill, fontSize);
        num++;
      });
      line++;
    });
  },

  getXk(xk, g, p){
    const bin = polinomKtoBinSimple(xk);
    const xr = xori(bin.toString(), g.toString(), p)
    return xr;
  },

  renderCandidate(x, y, fontSize, arcand, deltay, n, tn, k){
    //console.log('tn', tn);
    const param = '2. Кандидаты в примитивные полиномы: '+ (arcand.length-1) + ' из ' + tn + ', сразу рассчитаем x^'+(k-1);
    layer.add(this.addText(x, y, param, fontSize));
    x+=30;
    y+=15;

    let maxlength = n+1;
    if(n < 7 ) maxlength = 7;
    const deltax = maxlength * 13; 

    let line = 1, fill = 'black';
    arcand.forEach((subjects) => {
      //console.log('r', subjects[1]);
      let num = 1;
      subjects.forEach((data) => {
        if (data == -1) data = '#';
        this.draw(x, y, num, line, data, deltax, deltay, fill, fontSize);
        num++;
      });
      line++;
    }); 
  },

  defineCandidate(p,n,nk,k) {
    const xk = 'x^'+(k-1);
    let artest=[];
    const ra = this.permute(p,n);
    const ar = ra[0];
    //console.log('kol', ra[1]);

    // сделать таблицу:
    // k  Symbol  xk  Polynom  
    // 1  101     1   x^2+1  
    // 2  111     1   x^2+x+1
    let r=[];
    for(let i=0; i<ar.length; i++){
      const xki = this.getXk(xk, ar[i].toString(), p);
      if(xki == 1) artest.push(ar[i]);
      r.push([
        addLeadingZero((i+1).toString(), nk), 
        ar[i], 
        xki,
        bikToPolinom(ar[i])
      ]);
    } 
    r.push([-1, 'Symbol', xk, 'Polynom']); // заголовок
    r.sort(); 
    //console.log('r', r.length);
    return [r, ra[1], artest];
  },

  renderTotient(x, y, fontSize, k, n){
    const tot = (phi(k-1))/n;
    const param1 = '0. Количество примитивных полиномов определяется функцией Эйлера:';
    layer.add(this.addText(x, y, param1, fontSize));
    const param2 = 'ϕ(p^n-1)/n = ϕ('+(k-1)+')/'+n+' = ' + tot; // ϕ = U+03D5
    layer.add(this.addText(x+30, y+40, param2, fontSize));
  },

  renderArrbase(x, y, fontSize, arrbase, k){
    const param = '1. Сгенерировано базовое множество: ' + k + ' эл.';
    layer.add(this.addText(x, y, param, fontSize));
    layer.add(this.addText(x+30, y+40, arrbase, fontSize));
  },

  renderParam(x, y, fontSize){
    let param;
    if(!this.gene.length) {
      param = 'Не удалось определить кандидатов в порождающие полиномы';
    } else if(this.gene.length == 1) {
      param = 'Определен кандидат в порождающий полином: ' + this.gene;
    } else {
      param = 'Определены кандидаты в порождающие полиномы: ' + this.gene.length + ' шт. :  ' + this.gene.join(';  ');
    }
    layer.add(this.addText(x, y, 'GF(' + this.state.qgf + '); ' + param, fontSize));
  },

  draw(x, y, num, line, data, deltax, deltay, fill, fontSize) {
    layer.add(this.addText(((deltax*(num-1))-(deltax/(deltax/4))+x), 
      ((deltay*line)-(deltay/(deltay/5))+y), data, fontSize, 'Arial', fill));
  },

  addText(x, y, txt, fontSize, fontFamily, fill) {
    return new Konva.Text({
      x: x,
      y: y,
      text: txt,
      fontSize: fontSize,
      fontFamily: fontFamily, //'Calibri' 'Georgia' 'Arial'
      fill: fill, // 'red' 'black'
    });
  },

  permute(p,n){
    // составить массив возможных чисел, зависит от p
    let ar=[], i=0;
    while(i<p) { ar.push(i); i++; }

    const kol = permutateWithRepetitions(ar, n+1).length;

    let perm = permutateWithRepetitions(ar, n+1)
      .reduce((acc, item) => { 
        // начинается всегда на 1 и не может оканчиваться на 0
        if(item[0] == 1 && item[n] != 0) {
          acc.push(item);
        }
        return acc;      
    },[]);

    return [perm.map((subjects) => subjects.join('')), kol];
  },

  genvalid() {
    if (!this.v$.$invalid ) {
      this.generation();
    }
  },

  ...mapActions(["register"]),

}
}
</script>