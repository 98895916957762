<template>
  <v-content>
    <v-chip-group>
      &nbsp;&nbsp;
      <v-text-field
        label="полином"
        v-model="state.gg"
        append-inner-icon="mdi-select"
        @click:append-inner="genvalid"
        @keyup.enter="genvalid"
      ></v-text-field>
      &nbsp;&nbsp;   
    </v-chip-group>

    <div id="scroll-container">
      <div id="large-container">
        <div id="container"></div>
      </div>
    </div>
  </v-content>
</template>

<style>

  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f0f0f0;
    background: #fcfcfc;
    height: 100%;
    overflow: auto;
  }

  #large-container {
    width: 3000px;
    height: 3000px;
    overflow: hidden;
  }

  #scroll-container {
    width: calc(100% - 22px);
    height: calc(100vh - 22px);
    overflow: auto;
    margin: 10px;
    border: 1px solid grey;
  }  
</style>

<script>
//#region import
/* import { 
  expandbracketsmult,
  addLeadingZero,
  permutateWithRepetitions,
  bikToPolinom,
  found,
  isArraysEqual,
  multiplication,
  polinomKtoBin,
  polinomKtoBinSimple,
  phi,
  summation,
  xoro
 } from '../utils/common.js'; */
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators'; //
import Konva from 'konva';
//import { mapActions } from "vuex";
var width = window.innerWidth;
var height = window.innerHeight;
var PADDING = 500;
var layer = '';
//#endregion import

export default {
  data() {
    return { 
      configKonva: {
        width: width,
        height: height
      },
    };
  },

  mounted() {
  //#region Konva
    var stage = new Konva.Stage({
      container: 'container',
      width: window.innerWidth + PADDING * 2,
      height: window.innerHeight + PADDING * 2,//2
      draggable: true,
    });

    layer = new Konva.Layer();
    stage.add(layer);

    var scrollContainer = document.getElementById('scroll-container');

    function repositionStage() {
      var dx = scrollContainer.scrollLeft - PADDING;
      var dy = scrollContainer.scrollTop - PADDING;
      stage.container().style.transform =
        'translate(' + dx + 'px, ' + dy + 'px)';
      stage.x(-dx);
      stage.y(-dy);
    }

    scrollContainer.addEventListener('scroll', repositionStage);
    repositionStage();
  //#endregion Konva
    this.state.gg = 'x^4+x^3+1'; // 'x^8+x^5+x^4+1'
    this.generation();
    //console.log('->:', this.xoo('10', '1121', 3));
  },

  methods: {

    generation(){
      layer.removeChildren();// очистить в начале
      
      //простой пример 
      /*const d1 = '100111010000';
      const g = '11001';
      console.log('1->:', xoro(d1, g));
      const d2 = '100111010010';
      console.log('2->:', xoro(d2, g)); */

      //нужен пример на полноценном полиноме (1-Wire bus)
      //const g1 = '100110001'; //x^8+x^5+x^4+1  
      //const d3 = '10011101000010100101010010011101000010100101010000001010'; // 56
      //console.log('d:', d3.length);
      //console.log('1->:', xoro(d3, g1)); // получил 11100100


      





      const x=20, y=20, fontSize=24;
      layer.add(this.addText(x, y, 'CRC', fontSize)); 
      //console.log('->', this.checkedWithTables);
    },

//#region     

    draw(x, y, num, line, data, deltax, deltay, fill, fontSize) {
      layer.add(this.addText(((deltax*(num-1))-(deltax/(deltax/4))+x), 
        ((deltay*line)-(deltay/(deltay/5))+y), data, fontSize, 'Arial', fill));
    },

    addText(x, y, txt, fontSize, fontFamily, fill) {
      return new Konva.Text({
        x: x,
        y: y,
        text: txt,
        fontSize: fontSize,
        fontFamily: fontFamily, //'Calibri' 'Georgia' 'Arial'
        fill: fill, // 'red' 'black'
      });
    },

    genvalid() {
      if (!this.v$.$invalid ) {
        this.generation();
      }
    },

//#endregion 
  },

  setup () {
    const state = reactive({ 
      gg: '',
    })
    const rules = {
      gg: { required },
    }
    const v$ = useVuelidate(rules, state)
    return { state, v$ }
  },

}
</script>