
var err = '?';
var cursor = " \u2192 ";

function defineDegree(p) { 
    // Определить степень полинома (x^4) -> 4
    let r = 0;
    if (p.includes('^')) { 
        const degPos = p.indexOf("^");
        r = p.substring(degPos+1);
    } else {
        r = 1;
    }
    return r;
}

function polinomToBinSimple(p) {
    //'x'->10; 'x+1'->11; 'x^2'->100; 'x^2+1'->101; 'x^2+x'->110; 'x^2+2*x+2'->122;
    ////console.log('p:', p);   
    if (!isNaN(Number(p))) return p.toString(2);// если число вернуть в бинарном виде
    const ar = p.split('+'); 
    let n = 0;
    if (ar[0].includes('^')) n = Number(defineDegree(ar[0])) + 1;
        else n = 2; // не содержит '^' значит первая степень, два знака 
    let rar = Array(n).fill(0);
    ar.reverse();// разобрать в обратном порядке:'x^2+2*x+2' -> '2, 2*x, x^2'
    for (let i = 0; i < ar.length; i++) {  
        if (!isNaN(Number(ar[i]))) {
        rar[0] = ar[i];
        } else if(ar[i].includes('*') && !ar[i].includes('^')) { // && ar[i].includes('x'))  
        const num = ar[i].split('*')[0];
        ////console.log(':', ar[i]); 
        rar[1] = num;
        } else if (ar[i] == 'x') {
        rar[1] = '1';
        } else if (ar[i].includes('^')) { // для остальных степень после знака '^'
        ////console.log(':', ar[i]); 
        const deg = ar[i].split('^')[1];
        if(ar[i].includes('*')) {
            rar[deg] = ar[i].split('*')[0];
        } else {
            rar[deg] = '1';
        }
        }  
    }
    ////console.log('rar:', rar); 
    return rar.reverse().join(''); 
}

function numberMultPolinom(s){ 
    // Умножение числа на полином, вернуть бинарник
    // приходит типа 2*x или x*2 вернуть 100
    let p = '';
    // Разобрать строку на до '*'' и после
    const multPos = s.indexOf("*");
    const op1 = s.substring(0, multPos);
    const op2 = s.substring(multPos+1);

    // Кто из них число?
    let n = Number(op1);
    if (!isNaN(n)) { // op1 - число, значит op2 - полином
      p = polinomToBinSimple(op2);
    } else { 
      p = polinomToBinSimple(op1);
    }
    // Бинарник перевести в десятичную систему
    p = parseInt(p, 2);

    // Получил два десятичных числа, типа 2 и 2, произвести умножение
    const m = n*p;
    
    // вернуть бинарник от произведения 
    return m.toString(2);
}

function polinomToBin(p) { 
    // Любой полином в бинарный вид
    let rar = []; //r='', n=0

    if (p==1) return 1;
    
    // если пришло число отдать его в бинарном виде
    if (!isNaN(Number(p))) {
    return p.toString(2);
    }
    //console.log('p1', p);

    let ar = p.split('+'); 
    ar.sort;
    ////console.log('p2', ar);

    // разобрать каждый элемент, определить степень и записать в новый массив
    for (let i = 0; i < ar.length; i++) {
    if (ar[i].includes('*')) { // содержит элемент знак '*'
        const b = numberMultPolinom(ar[i]);
        const d = parseInt(b, 2); 
        rar.push(d);  
    } else {
        //console.log('p3', ar);
        const num = Number(ar[i]);
        if (!isNaN(num)) {
            const b = polinomToBinSimple(num);
            const d = parseInt(b, 2);
            rar.push(d);
        } else {
            //console.log('p4', ar);
            const b = polinomToBinSimple(ar[i]);
            const d = parseInt(b, 2);
            rar.push(d);
        }
    }
    }

    // сложить числа в десятичной системе
    let sum = 0;
    for (let i = 0; i < rar.length; i++) {
    sum += rar[i];
    } 

    // сумму в десятичной системе в бинарный вид
    const b = sum.toString(2);

    return b;
}

function polinomKtoBinSimple(s) { 
    // без '+' 'x^2'->100; '2*x^2'->200; 
    let r = '';
    //console.log('s:', s);
    if(s.includes('*') && s.includes('^')) { // типа '2*x^2'
        const m = s.split('*')[0];
        r = m * polinomToBin(s.split('*')[1]);
        ////console.log('r:', r);
    } else if(s.includes('^') && !s.includes('*')) {
        //console.log('s1:', s);
        r = polinomToBin(s);
    } else {
        r = s.replace('x', polinomToBin('x'));
        r = calculate(r);
    }
    //return Number(r);
    return r;
}

function polinomKtoBin(s) { 
  //console.log('s:', s);
  // содержит '+' типа: '2*x^2+2'->202
  let ar = s.split('+'); 
  //console.log('ar1:', ar);
  let r = 0;
  for (let i = 0; i < ar.length; i++) {
    r+=Number(polinomKtoBinSimple(ar[i]));
    //console.log(':', e);
  }
  return r;
}

function maxLengthStr(arr) {
  return arr.sort((a, b) => b.length - a.length)[0].length;
}

function containsSymbol(sym, ar) { 
    // Возвращает true если найден символ в элементах массива
    for (let i = 0; i < ar.length; i++) {
      if (ar[i].includes(sym)) return true;
    }
    return false;
}

function smashMultToArr(s){ 
    // Разбить произведение (типа 2*x) на множители в массиве {x,x}
    let r = [];

    const multPos = s.indexOf("*");
    const n = s.substring(0, multPos);
    const p = s.substring(multPos+1);

    if (!isNaN(Number(n))) {
      let i = 0;
      do {
        i = i + 1;
        r.push(p);
      } while (i < n);
    }

    return r;
}

function spreadArrToX(arr){ 
    // Разбить на мелочь ('x') 
    //('2', '2*x') -> ('2', 'x', 'x')
    //['x^2', 'x^2'] -> ['x', 'x', 'x', 'x']
    let r =[];
    //console.log('ar1', arr);
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].includes('*')) { // если содержит '*' (2*x) разобрать на {x,x}
        const smashArr= smashMultToArr(arr[i]);
        for (let j = 0; j < smashArr.length; j++) {
          r.push(smashArr[j]);
        }
      } else if(arr[i].includes('^')) { 
        // todo так как реально не умножаются просто возвращаем кол-во x, кроме complexmode
        let k=0;
/*         if (complexmode) {
          if (arr[i] == 'x^2') r.push('2'); 
        } else { */
          const ndeg = defineDegree(arr[i]);
          //console.log('ndeg', ndeg);
          do {
            k = k + 1;
            r.push('x');
          } while (k < ndeg);
        //}        
      } else { 
          r.push(arr[i]);
      }
    }
    return r;
}

function multel(s) { 
  // Умножение операндов (x*2*x) -> (2*x^2) (без знаков '+') 
  //console.log('1:', s);

  let ar = s.split('*'); // в массив и отсортировать
  ar.sort();
  //console.log('2:', ar);

  // если нет 'x', то можно просто перемножить все элементы массива
  if (!s.includes('x')) {
    const res = ar.reduce((acc, rec) => acc * rec);
    return res;
  }
  //console.log('3:', ar);

  //сразу сократить x^2=2 если complexmode
/*     if(complexmode) {
    for(let i=0; i<ar.length; i++){
      if(ar[i]=='x^2'){
        ar[i] = xmode;
      }
    } 
    // еще раз, если нет 'x', то можно просто перемножить все элементы массива
    if(!containsSymbol('x', ar)) {
      const res = ar.reduce((acc, rec) => acc * rec);
      return res;
    }
  //}*/
  //console.log('ar4:', ar);
  
  let x = ar.filter((e) => e.includes('x')); // собрать содержащее 'x'
  //console.log('x:', x);

  let m = 0;
  const mar = ar.filter((e) => !e.includes('x'));
  //console.log('mar:', mar);
  if(mar.length) {
    //console.log('x, m', x, m);
    //m = mar.reduce((acc, rec) => acc * rec).toString().split('');
    m = mar.reduce((acc, rec) => acc * rec); // 050724 исправил
  }
  //console.log('m:', m);
  // получилось два операнда: содержащий x, число m - оставляем без изменений 

  // расслоить x^2 на x,x 
  x = spreadArrToX(x);
  //console.log('3:', x);

  // посчитать количество одинаковых элементов
  const kv = x.reduce((acc, i) => {
    if(Object.prototype.hasOwnProperty.call(acc, i)) {
      acc[i] += 1;
    } else {
      acc[i] = 1;
    }
    return acc;
  },{})
  
  let r='';
  // собрать в группы и добавить знак '*'
  for (let key in kv) {
    if (key=='x') {
      if (kv[key]==1) {
        r = r + key + '*';  
      } else {
/*           if (complexmode) {
          const kk = key + '^' + kv[key];
          if (kk=='x^2') r = r + '2*';
        } else { */
          r = r + key + '^' + kv[key] + '*';
        //}
      }
    } else {  
      r = r + key * kv[key] + '*';
    }
  }

  if(m!=0) {
    return m+'*'+r.slice(0, -1);
  } else {
    return r.slice(0, -1);
  }
  
  // не нужен - так как умножаем, то в элементах 'ar' можно удалить '1' 
  // ar = reduceElArrK('1', ar, 1);

}

function calculate(s) { 
    // для расчета без 'x'
    let r=0, ar=[];
    
    if(s.includes('+')) {
      ar = s.split('+'); // если есть + разложить в массив по '+'
    } else {
      ar = s.split();
    }  

    for(let i=0; i < ar.length; i++) {
      ////console.log('ar[i]',ar[i]);
      if(ar[i].includes('*')) {
        r += multel(ar[i]);
      } else {
        r += Number(ar[i]); 
      }
    }

    return r;
}

function phi(n) {
    // return Greater Common Denominator of two given numbers
    function gcd(a, b) {
        if (a === 0) {
        return b;
        }
        return gcd(b % a, a);
    }
    // init
    var result = 1;
    // walk through all integers up to n
    for (let i = 2; i < n; i++) {
        if (gcd(i, n) === 1) {
        result++;
        }
    }
    return result;
}

function found(el, ar) { 
    // Возвращает true если найден
    const f = ar.find(element => element == el);
    if (f === undefined) { // Не найден
      return false; 
    } else { // Найден
      return true;
    } 
}

function xorimod(a,b,ch){
    //console.log('ab',a,b);
    if (b === undefined) b = '0';
    if (a === b) {
      return 0;
    } else if ( a >= b ) {
      return a-b;
    } else return a-b+ch;
}

function xori(a,g,ch) {
/*   a=a.toString();
  g=g.toString(); */
  let r = '';
  for (let i = 0; i < a.length; i++) {
      r += xorimod(a[i], g[i], ch);
  }
  //console.log('r:', r);
  r = removeLeadingZeroStr(r.toString());
  if (r.length >= g.length) {
      r = xori(r.toString(), g.toString(), ch);
  }
  return Number(r);
}

function xoro(a, g) { 
  // Рекурсивная операция XOR с порождающим полиномом возвращающая остаток
  let r = '';
  for (let i = 0; i < Math.max(a.length, g.length); i++) {
    r += (a[i] ^ g[i]);
  }
  //console.log('r:', r);
  //r = Number(r).toString();
  r = removeLeadingZeroStr(r);
  if (r.length >= g.length) {
    r = xoro(r, g);
  }
  return Number(r);
}

function addLeadingZero(s, n) { 
    // добавить лидирующие нули количеством n
    let r = s;
    if (s.length < n) {
      do {
        r = '0' + r;
      } while (r.length < n);
    }
    return r.toString(); // todo 29.06 add .toString()
 }

function isArraysEqual(ar1, ar2) { 
    // сравнить массивы
    return JSON.stringify(ar1.sort()) === JSON.stringify(ar2.sort());
}

function binToPolinom(b){ 
  // Бинарный вид в полиномиальный
  if(b==1 || b=='1') return 1;

  const ar = b.split('');
  let r = '';
  let d = ar.length-1;
  let deg = '';
  for (let i = 0; i < ar.length; i++) {
    deg = (d>1) ? '^' + d : '';
    if (i == 0 && ar[i] > 0) {
      r = 'x' + deg;           
    } else if (ar[i] > 0 && i != ar.length-1) {
      r = r + '+x' + deg;
    } else if (ar[i] != 0 && i == ar.length-1) {
      r = r + '+1';
    }
    d--;
  }
  return r;
}

function removeLeadingZeroBin(s) { 
  // убрать лидирующие нули в строке, для таких: 000,001,010,011
  return parseInt(s, 2).toString(2);
}

function removeLeadingZeroStr(s){
  //console.log('s', s);
  return s.replace(/(?<=^|-)0+/, '');
}

function removeLeadingZeroArr(arr) { 
  // убрать лидирующие нули в элементах в бинарном виде 
  // для таких: 000,001,010,011
  let ar = [];
  for (let i = 0; i < arr.length; i++) {
    ar.push(parseInt(arr[i], 2).toString(2));
  }
  return ar;
}

function bikToPolinom(b, ch){ 
  // бинарный вид c коэффициентом в полиномиальный
  // 110->x^2+x; 122->x^2+2*x+2
  const ar = b.split('');

  // поймать '00'
  if(ar.reduce((acc, rec) => Number(acc) + Number(rec)) == 0) return 0;

  if(b[0] == '0') {// поймать '01', '02' и т.п. до ch больше ch не рассматривал
    const n = removeLeadingZeroStr(b);
    if(n<ch) return n;
      else if (n == ch) return 'x';
  } else {
    if(b<ch) return b;
      else if (b == ch) return 'x';
  }

  let p = '', d = ar.length-1, deg = '';
  for (let i = 0; i < ar.length; i++) {
    deg = (d>1) ? '^' + d : '';
    if (i == 0 && ar[i] > 0) {
      //console.log('1:', ar[i]);
      if(ar[i] > 1){
        p = ar[i] +'*x' + deg +'+'; // +'+'
      } else {
        p = 'x' + deg +'+';// +'+'
      }          
    } else if (ar[i] > 0 && i != ar.length-1) {
      //console.log('2:', ar[i]);

      //p = p + '+x' + deg;
      if(ar[i] > 1){
        p = p + ar[i]+'*x' + deg + '+'; // p = p + '+'+ar[i]+'*x' + deg
      } else {
        p = p + 'x' + deg + '+'; // 
      } 
      //console.log('2p:', p);

    } else if (ar[i] != 0 && i == ar.length-1) {
      //console.log('3:', ar[i]);
      p = p + ar[i]; //p = p + '+' + ar[i];
    }
    d--;
  }

  //если кончается на '+' то удалить его
  //console.log('end:', p[p.length-1]);
  if(p[p.length-1] == '+') {
    p = p.slice(0, -1);
  }

  return p;
}

function isPrime(num) {
  for (let i = 2; i < num; i++) {
    if (num % i === 0) return false;
  }
  return num !== 1;
}

function permute(arr) {
  //Создание всех возможных перестановок массива https://sky.pro/wiki/javascript/sozdanie-vsekh-vozmozhnykh-perestanovok-massiva-v-java-script/
  const result = [];
  const swap = (a, i, j) => ([a[i], a[j]] = [a[j], a[i]]);
  const generate = (k, heapArr) => {
    if (k === 1) {
      result.push(heapArr.slice());
      return;
    }
    generate(k - 1, heapArr);
    for (let i = 0; i < k-1; i++) {
      swap(heapArr, k % 2 ? 0 : i, k-1);
      generate(k-1, heapArr);
    }
  };
  generate(arr.length, arr.slice());
  return result;
}

function permuteUnique(arr) {
  const result = [];
  const swap = (a, i, j) => ([a[i], a[j]] = [a[j], a[i]]);
  const generate = (k, heapArr) => {
    if (k === 1) {
      const permutationStr = JSON.stringify(heapArr);
      if (!result.includes(permutationStr)) {
        result.push(permutationStr);
      }
      return;
    }
    generate(k-1, heapArr);
    for (let i = 0; i < k-1; i++) {
      swap(heapArr, k % 2 ? 0 : i, k-1);
      generate(k-1, heapArr);
    }
  };
  generate(arr.length, arr.slice());
  return result.map(JSON.parse);
}

function permutations(string) {
 /*  https://qna.habr.com/q/1071236 
  Это рекурсивный метод генерации всех перестановок. 
  Сначала рекурсивно генерируется перестановки для всех символов, кроме первого. 
  Потом к каждой перестановке в каждую возможную позицию вставляется первый символ.
  Так, для генерации всех перестановок "abc", 
  сначала рекурсивно будет получен массив {"bc", "cb"}, 
  потом для каждого его элемента в ответ будет добавлена перестановка с "a" 
  вставленным в позицию 0, 1 и 2: "abc", "bac", "bca" для первого элемента, 
  и "acb", "cab" и "cba" для второго.
 */
  if (string.length <= 1) {
    return [string];
  }
  
  let finalPermutations = permutations(string.substring(1)) 
    .reduce((acc, p) => { 
      let charList = p.split(''); 
      for (let i = 0; i <= charList.length; i++) { 
        let newPermutation = charList.slice(0, i)
                              .concat([string[0]])
                              .concat(charList.slice(i)) 
                              .join(''); 
        if (!acc.includes(newPermutation)) { 
          acc.push(newPermutation);
        } 
      }
      return acc;      
  },[]);
  return finalPermutations;
}

function combineWithRepetitions(comboOptions, comboLength) {
  // https://github.com/trekhleb/javascript-algorithms/tree/master/src/algorithms/sets/combinations
  // If the length of the combination is 1 then each element of the original array
  // is a combination itself.
  if (comboLength === 1) {
    return comboOptions.map((comboOption) => [comboOption]);
  }

  // Init combinations array.
  const combos = [];

  // Remember characters one by one and concatenate them to combinations of smaller lengths.
  // We don't extract elements here because the repetitions are allowed.
  comboOptions.forEach((currentOption, optionIndex) => {
    // Generate combinations of smaller size.
    const smallerCombos = combineWithRepetitions(
      comboOptions.slice(optionIndex),
      comboLength - 1,
    );

    // Concatenate currentOption with all combinations of smaller size.
    smallerCombos.forEach((smallerCombo) => {
      combos.push([currentOption].concat(smallerCombo));
    });
  });

  return combos;
}

function permutateWithRepetitions(
  permutationOptions,
  permutationLength = permutationOptions.length,
) {
  //https://github.com/trekhleb/javascript-algorithms/blob/master/src/algorithms/sets/permutations/permutateWithRepetitions.js
  if (permutationLength === 1) {
    return permutationOptions.map((permutationOption) => [permutationOption]);
  }

  // Init permutations array.
  const permutations = [];

  // Get smaller permutations.
  const smallerPermutations = permutateWithRepetitions(
    permutationOptions,
    permutationLength - 1,
  );

  // Go through all options and join it to the smaller permutations.
  // Просмотрите все варианты и присоедините их к меньшим перестановкам.
  permutationOptions.forEach((currentOption) => {
    smallerPermutations.forEach((smallerPermutation) => {
      permutations.push([currentOption].concat(smallerPermutation));
    });
  });

  return permutations;
}

function reduceElArrK(el, arr, k) { 
  // Удаляет из массива элемент el количеством k 
  // Например удалить '0' один раз, reduceElArrK('0', arr, 1); 
  let ar = [];
  let j = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] == el && j<k) {
      j++;
    } else {
      ar.push(arr[i]);
    }
  }
  return ar;
}

function spreadArrToOnePlusX(ar){ 
  // Разбить на мелочь 
  // ['2', '2*x'] -> ['1', '1', 'x', 'x']
  // ['2*x+4'] -> ['1', '1', '1', '1', 'x', 'x']
  // x^2 в зависимости от настройки complexmode
  let r=[], arr=[];
  for (let i = 0; i < ar.length; i++) {
    if (ar[i].includes('+')) {
      let ar1 = ar[i].split('+');
      for (let j = 0; j < ar1.length; j++) {
        arr.push(ar1[j]);  
      }
    } else {
      arr.push(ar[i]);
    }
  }
  for (let i = 0; i < arr.length; i++) {  
    const n = Number(arr[i]);
    if (!isNaN(n) &&  n > 1) {
      for (let j = 0; j < n; j++) {
        r.push('1');
      }
    } else {
      if (arr[i].includes('*')) { // если содержит '*' (2*x) разобрать на {x,x}
        const smashArr= smashMultToArr(arr[i]);
        for (let j = 0; j < smashArr.length; j++) {
          r.push(smashArr[j]);
        }
      } else if(arr[i].includes('^')) {
/*         if (complexmode) {// для complexmode x^2=2
         if (arr[i] == 'x^2') r.push('2'); 
        } else { */
          r.push(arr[i]);
        //}
      } else { 
         r.push(arr[i]);
      }
    }
  }
  r.sort();
  return r;
}

function reduceArrDoublK(arr, k){ 
  // Удаляет из массива дубли элементов количеством k 
  arr.sort(); 

  // Посчитать количество одинаковых элементов в (KeyValue)
  const kv = arr.reduce((acc, i) => {
    if(Object.prototype.hasOwnProperty.call(acc, i)) {
      acc[i] += 1;
    } else {
      acc[i] = 1;
    }
    return acc;
  },{})

  // Собрать элементы количеством больше/равно k
  let armorek = []; 
  for (let key in kv) {
    if (kv[key] >= k) {
      armorek.push(key);
    }
  }

  // Сократить по количеству k, например для 1+1=0, по характеристике 2 
  for (let i = 0; i < armorek.length; i++) {
    arr = reduceElArrK(armorek[i], arr, k);
  }

  // Еще раз подсчитать и Собрать элементы количеством больше/равно k
  const kv1 = arr.reduce((acc, i) => {
    if(Object.prototype.hasOwnProperty.call(acc, i)) {
      acc[i] += 1;
    } else {
      acc[i] = 1;
    }
    return acc;
  },{})
  let armorek1 = []; 
  for (let key in kv1) {
    if (kv1[key] >= k) {
      armorek1.push(key);
    }
  }
  if(armorek1.length) {
    arr = reduceArrDoublK(arr, k);
  }

  return arr.reverse();
}

function collectGroups(arr, ch){ 
  // собрать в группы: {'x', 'x', '1', '1'} -> {2, '2*x'}
  // ф собирает элементы в группы количеством меньше характеристики ch (для 3^2 будет 2) которые есть в базе
  let r=[];
  
  // посчитать количество одинаковых элементов
  const kv = arr.reduce((acc, i) => {
    if(Object.prototype.hasOwnProperty.call(acc, i)) {
      acc[i] += 1;
    } else {
      acc[i] = 1;
    }
    return acc;
  },{})
  
  // собрать в массив пары количеством меньше ch
  for (let key in kv) {
    if (kv[key] < ch) {
      if (key=='1') {
        r.push(kv[key]);
      } else if (kv[key] == 1) {
        r.push(key);
      } else {
        r.push(kv[key] + '*' + key);
      }
    }
  }

  return r.sort().reverse();
}

function transPolinomK(arr, ch, g){ 
  // ['x^2', 'x'] Сначала сложить в bin 
  //console.log('arr:', arr, ch, g);
  let r = 0;
  for(let i=0; i<arr.length; i++) {
    const num = Number(arr[i]);
    if (!isNaN(num)) {
      //console.log('num:', num);
      r+=num;
    } else {
      r+=polinomKtoBin(arr[i]);
    }
  } 
  //console.log('r:', r);
  const xor = xori(r.toString(), g.toString() ,Number(ch));
  //console.log('xor:', xor);
  const w = r + ' xori '+ g + ' = ';
  return [xor,w];
}

function reduceByCharacteristic(arr, ch){ 
  // Сокращает arr по характеристике
  // ['4','5*x'] -> [1,'2*x'] (ch=3)
  arr.sort(); 
  // разбить на "мелочь": 1, x 
  arr = spreadArrToOnePlusX(arr);

  // Посчитать количество одинаковых элементов в (KeyValue)
  const kv = arr.reduce((acc, i) => {
    if(Object.prototype.hasOwnProperty.call(acc, i)) {
      acc[i] += 1;
    } else {
      acc[i] = 1;
    }
    return acc;
  },{})

  // Собрать элементы количеством больше/равно ch
  let armorek = []; 
  for (let key in kv) {
    if (kv[key] >= ch) {
      armorek.push(key);
    }
  }

  // Сократить по количеству ch, (например 1+1=0, по характеристике 2) 
  for (let i = 0; i < armorek.length; i++) {
    arr = reduceElArrK(armorek[i], arr, ch);
  }

  // Собрать в группы
  arr = collectGroups(arr, ch);

  return arr.reverse();
}

function expandbracketsum(s, ch) { 
  // Раскрыть скобки c сокращением
  let r=[]; 
  const ar = s.split('+');

  for (let i = 0; i < ar.length; i++) {
    if(ar[i].includes('x')) {
      r.push(multel(ar[i]));
    } else {
      r.push(calculate(ar[i]));
    }
  }

  r = r.join('+');
  r = spreadArrToOnePlusX(r.split());
  r = reduceArrDoublK(r, ch);
  r = collectGroups(r, ch);

  return r;
}

function expandbracketsmulNoReduce(a, b) { 
  // Раскрыть скобки без сокращения
  let r = [], c = '';
  //console.log('a,b', a, b);

  //1. разложить a, b на операнды по '+'' в массивы
  const aar = a.split('+');
  const bar = b.split('+');

  //2. умножить каждый операнд bar на каждый операнд aar 
  for (let i = 0; i < bar.length; i++) {
    for (let j = 0; j < aar.length; j++) {
      //console.log('aar[j]', aar[j]);
      if(bar[i]==1) {
        c = c + aar[j] + '+';
      } else if(aar[j]==1) {
        c = c + bar[i] + '+';
      } else {  
        c = c + bar[i] + '*' + aar[j] + '+';
      }
    }
  }
  c = c.slice(0, -1);
  //console.log('c', c);

  //3. 'c' в массив по '+''
  const ar = c.split('+');
  //console.log('ar', ar);

  for (let i = 0; i < ar.length; i++) {
    r.push(multel(ar[i]));
  }
  //console.log('r', r);

  return r.sort().reverse().join('+');
}

function getIndex(ch){
  switch (ch) {
    case 2: return "\u2082";
    case 3: return "\u2083";
    case 4: return "\u2084";
    case 5: return "\u2085";
    case 6: return "\u2086";
    case 7: return "\u2087";
    case 8: return "\u2088";
    case 9: return "\u2089";
    case 10: return "\u2081\u2080";
  }
}

function noContainSymbolS(symar, s) { 
  // Возвращает true если в s нет ни одного символа из symar
  // используется для отделения g '122' от '101' например
  for(let i=0; i < symar.length; i++) {
    if(s.includes(symar[i])) return false;
  }
  return true;
}

function polinomToDec(p) { 
  // Любой полином в десятичную систему
  
  if (!isNaN(Number(p))) {// если пришло число отдать его обратно
    return p;
  }
  
  let ar = p.split('+'); 
  ar.sort;

  // разобрать каждый элемент, определить степень и записать в новый массив
  let rar = [];
  for (let i = 0; i < ar.length; i++) {
    if (ar[i].includes('*')) { // содержит элемент знак '*'
      const b = numberMultPolinom(ar[i]);
      const d = parseInt(b, 2); 
      rar.push(d);  
    } else {
      const num = Number(ar[i]);
      if (!isNaN(num)) {
        const b = polinomToBinSimple(num);
        const d = parseInt(b, 2);
        rar.push(d);
      } else {
        const b = polinomToBinSimple(ar[i]);
        const d = parseInt(b, 2);
        rar.push(d);
      }
    }
  }

  // сложить числа в десятичной системе
  let sum = 0;
  for (let i = 0; i < rar.length; i++) {
    sum += rar[i];
  } 

  return sum;
}

function calcdeg(a, n, arrbase, ch){// 
  //console.log('a, n:', a, n);
  let r = 1;
  for (let i = 1; i <= n; i++) {
    r *= a;
  }
  if (!found(r, arrbase)) {
    r = r % ch;
    if (!found(r, arrbase)) {
      r = err;
    }
  }
  //console.log('r:', r);
  return r;
}

function checkBik(g){
  //если g состоит не только из 0 и 1 
  if(noContainSymbolS(['2','3','4','5','6','7','8','9'], g.toString())) { // bin
    return false;
  } else { // bik 
    return true;
  }
}

function factorial(n, result){
  result = result || 1;
  if(!n){
      return result;
  }else{
      return factorial(n-1, result*n);
  }
}

//#region summ 

function summation(arrbase, a, b, g, ch, mode) {
  let r = err, w='';
  //console.log('a, b:', a, b);

  if(mode == 0) { // Полиномиальный режим  
    if (a==0 && b.includes('x')) { // 0+x
      r = b;
    } else if (a.includes('x') && b==0) { // x+0
      r = a;
    } else {
      const ra = summAllPolinom(a, b, ch, arrbase);
      r=ra[0];
      w=ra[1];
    }
   } else if (mode == 1) { // Числовой режим 
    const anum = Number(a);
    const bnum = Number(b);
    if (!isNaN(anum) && !isNaN(bnum)) {
      const ra = summNumbers(anum, bnum, arrbase, ch);
      r=ra[0];
      w=ra[1];
    }
  } else if (mode == 2) { // Двоичный режим (bin или bik)
    if(checkBik(g)) { // bik  
      const ra = sumBikMode(a, b, ch, g, arrbase); 
      r=ra[0];
      w=ra[1];
    } else { // bin 
      const ra = sumBinMode(a, b, arrbase, ch, g);
      r=ra[0];
      w=ra[1];    
    }
  } 
  return [r,w];
}

function sumBinMode(a, b, arrbase, ch, g){
  let r = err;

  //if(a=='100' && b=='110') {    
  const ad = parseInt(a, 2);// каждый операнд в 10-ю систему
  const bd = parseInt(b, 2);
  //console.log('ad, bd', ad, bd);

  // посчитать с '0'
  if (ad==0 || bd==0) {
    if (ad==0) {
      r = addLeadingZero(b, a.length);
    } else if (bd==0) {
      r = addLeadingZero(a, a.length);
    }
    return [r,r];
  }
  const arr = removeLeadingZeroArr(arrbase);
  if(g>0) { //если g заполнен, то через полиномы
    const ra = sumWithPolinom(a, b, ch, arrbase);
    return [ra[0],ra[1]];
  } else {
    const sum = ad + bd; // складываю в 10-й системе
    //console.log('sum', sum);
    const bin = sum.toString(2); // sum перевожу в бинарный вид 
    //console.log('bin', bin);
    if (found(bin, arr)) {
      return [addLeadingZero(bin, a.length),addLeadingZero(bin, a.length)];
    //} else if(found(sum, arr)) { убрал из-за ошибок в gf_7_1_bin
      //return [addLeadingZero(sum.toString(), a.length),addLeadingZero(sum.toString(), a.length)];
    } else {
      const ra = useMod(sum, bin, ch, arr, a.length);
      return [ra[0],ra[1]];
    }
  }

  //} return [r,err];
}

function useMod(n, bin, ch, arr, length){
  const mod = n % ch; 
  const modb = mod.toString(2);
  if (found(modb, arr)) {
    const r = addLeadingZero(modb, length);
    const w = bin + cursor + n + " mod " + ch + " = " + r;
    return [r,w];
  } else {
    return [err,err];
  }
}

function sumWithPolinom(a, b, ch, arrbase){ 
  let r=err, c='', d='', e='', f='', h='' ; //
  //console.log('a, b', a, b);

  //if(a=='01' && b=='11') {

  // Перевести каждый операнд в полином
  const ap = bikToPolinom(removeLeadingZeroBin(a), ch); // binToPolinom
  const bp = bikToPolinom(removeLeadingZeroBin(b), ch);
  c = "("+ap+") + ("+bp+")";
  //console.log('c', c);
  let p = ap + '+' +bp;
  //console.log('p', p);
  //d = cursor + p; // Убираю показ, кажется лишним
  //console.log('d', d);
  // Сократить полином по характеристике ch 
  p = spreadArrToOnePlusX(p.split());// разобрать на мелочь
  const reduce = reduceArrDoublK(p, ch);// удалить дубли (1,x) количеством ch
  //console.log('reduce', reduce);
  if(!reduce.length) { // характеристика "убила" все элементы
    r = addLeadingZero('0', a.length);
    e = cursor + r;
    return [r,c+d+e];
  } else if (reduce.length == 1) { // в массиве 1 элемент
    e = cursor + reduce[0];
    r = addLeadingZero(reduce[0].toString(), a.length);
    if (found(r, arrbase)) {
      f = cursor + r;
      return [r,c+d+e+f];  
    } else { // остался 'x'
      const bin = polinomToBin(reduce[0]);
      r = addLeadingZero(bin.toString(), a.length);
      if (found(r, arrbase)) {
        h = cursor + r;
        return [r,c+d+e+f+h];
      } else return [err,c+d+e+f+h+err];
    }
  } else { // в массиве несколько элементов, типа 'x,1'
    f = cursor + reduce.join('+');
    //console.log('reduce', reduce);
    const bin = polinomToBin(reduce.join('+'));
    //console.log('bin', bin);
    r = addLeadingZero(bin.toString(), a.length);
    if (found(r, arrbase)) {
      h = cursor + r;
      return [r,c+d+e+f+h];
    } else return [err,c+d+e+f+h+err];
  } 

  //}//
  //return [err,c+d+e+f+h+err];
}

function sumBikMode(a, b, ch, g, arrbase){
  let r = err, c='';
  
  //if(a=='01' && b=='11') { 
  const ad = parseInt(a, Number(ch)); //
  const bd = parseInt(b, Number(ch));
  //console.log('ad, ad', ad, bd);   
  if (ad==0 || bd==0) { // посчитать с '0'
    if (ad==0) {
      r = addLeadingZero(b, a.length);
    } else if (bd==0) {
      r = addLeadingZero(a, a.length);
    }
  }
  //console.log('ad, bd', ad, bd);
  const sum = Number(a)+Number(b); //010724 было: const sum = Number(ad)+Number(bd);
  //console.log('sum', sum);
  const sz = addLeadingZero(sum.toString(), a.length);
  //console.log('sz', sz);
  if (found(sz, arrbase)) {
    return [sz,sz];
  } else { // если не найдено, сократить по mod
    if((Number(ad) < Number(ch)) && (Number(bd) < Number(ch))) {// сокращать по mod только если каждый операнд меньше ch
      const mod = sum % ch;
      const mz = addLeadingZero(mod.toString(), a.length);
      //console.log('mz', mz);
      if (found(mz, arrbase)) {
        c = sum + cursor;
        r=mz;
      } else {
        let s = sum.toString(2);
        s = addLeadingZero(s, a.length);
        //console.log('s', s);
        if (found(s, arrbase)) {
          r=s;
        }
      }
    } else { // трансформировать каждый операнд в полином
      const ap = bikToPolinom(a.toString(), Number(ch));
      const bp = bikToPolinom(b.toString(), Number(ch));
      //console.log('p', ap, bp);
      const s = ap+'+'+bp;
      //console.log('s:', s);
      let arr = s.split('+');
      arr.sort();
      c = s + cursor;
      //console.log('1:', c);
      arr = spreadArrToOnePlusX(arr);// разобрать полином на: 1 и x
      //console.log('2:', arr);
      arr = reduceArrDoublK(arr, ch);// удалить дубли по 1 и x количеством ch
      //console.log('3:', arr);
      if(!arr.length) { // характеристика "убила" все элементы
        const nol = addLeadingZero('0', a.length);
        r=nol;
      } else if (arr.length == 1) { // в массиве 1 элемент
        //console.log('4:', arr[0]);
        const bin = polinomToBin(arr[0]);
        const bz = addLeadingZero(bin.toString(), a.length);
        //console.log('5:', bz);
        if (found(bz, arrbase)) {// проверить на нахождение в базе
          r=bz;
        } else return [r,r];
      } else { // осталось больше 1 элемента
        let sum = 0;
        for(let i=0; i<arr.length; i++) {
          sum += Number(polinomToBin(arr[i]));
        }
        const sz = addLeadingZero(sum.toString(), a.length);
        //console.log('sz:', sz);
        if (found(sz, arrbase)) {
          r=sz;
        } else return [r,r];
      }
    } 
  }

  //}// 
  return [r,c+r];
}

function comAlg(sum, arrbase, ch, mode){
  let r = '', a = '', b = '', er = '';
  // Проверить, входит результат в исходный масcив?
  //console.log('sum:', sum);
  if (found(sum, arrbase)) { // найден, возвращаем его
    r = sum;
  } else { // не найден, применить mod
    r = sum % ch;
    a = cursor + sum + " mod " + ch + " = " + r;
    // Еще раз проверить, что результат входит в arrbase?
    if (!found(r, arrbase) && mode == 0) { // не найден и полиномиальный режим
      // привести в бинарный вид далее в полиномиальный
      const bin = r.toString(2); 
      r = binToPolinom(bin); //binToPolinom
      const pln = binToPolinom(bin); //binToPolinom
      b = cursor + bin + getIndex(ch) + cursor + pln;
      if (!found(r, arrbase)) { // не найден
        er = err;
      }
    } 
  }
  return [r, a+b+er];
}

function summNumbers(a, b, arrbase, ch){ 
  if (a == b && ch == 2) {//Для характеристики=2 сравнить a и b, если равны возвратить 0
    return [0,0];
  }
  const sum = a + b;
  const ra = comAlg(sum, arrbase, ch);
  return [ra[0],sum + ra[1]];
}

function summAllPolinom(a, b, ch, arrbase, maxlength) {
  let r = '', w = '';
  // если сумма длин двух элементов меньше или равна maxlength (наибольшая длина элемента базы),
  // то скорее всего конкатенация двух элементов будет найдена в базе
  if ((a.length + b.length) <= maxlength) { 
    const ab = a+"+"+b;
    const ba = b+"+"+a;
    if (found(ab, arrbase)) {
      r = w = ab;  
    } else if (found(ba, arrbase)) {
      r = w = ba;
    } else { // если не найдено, то тоже что и ниже
      const ra = summPolinom(a, b, ch, arrbase); // 
      r = ra[0];
      w = ra[1];
    }
  } else { // остальные полиномы
    const ra = summPolinom(a, b, ch, arrbase); // 
    r = ra[0];
    w = ra[1];
  }
  return [r,w];
}

function summPolinom(a, b, ch, arrbase){
  let r='', w='';

  const s = a+'+'+b; // добавить '+' между a и b, получим типа: "2*x+1+x+1"
  let arr = s.split('+');
  arr.sort();

  //Для не найденных на первом этапе, типа: (x)+(x^2+1) нужно засунуть 'x' в середину, т.е. (x^2+x+1) который есть в базе
  arr.reverse();
  const rev = arr.join('+');
  if (found(rev, arrbase)) return [rev,rev]; 

  let spreadarr = spreadArrToOnePlusX(arr);// разобрать полином на мелочь: 1 и x
  // получили типа ['1', '1', '1', '1', 'x', 'x', 'x', 'x']

  // удалить дубли по 1 и x количеством ch
  spreadarr = reduceArrDoublK(spreadarr, ch);
  if(!spreadarr.length) { // характеристика "убила" все элементы
    r = w = 0;
  } else if (spreadarr.length == 1) { // в массиве 1 элемент
    // Проверить на нахождение в базе
    if (found(spreadarr[0], arrbase)) { // найден, возвращаем его
      r = w = spreadarr[0];
    } else {
      const ra = summArr(spreadarr, ch, arrbase);//
      r = ra[0];
      w = ra[1];     
    }
  } else { 
    const ra = summArr(spreadarr, ch, arrbase);//
    r = ra[0];
    w = ra[1];
  }

  return [r,w];
}

function summArr(arr, ch, arrbase){
  let r='', w='';
  
  const collGroups = collectGroups(arr, ch);
  
  if (collGroups.length == 1) {
    if (found(collGroups[0], arrbase)) { // найден, возвращаем его
      r = w = collGroups[0];
    } else {
      //console.log('collGroups', collGroups); // попалось 2*x, это x+x
      const ra = summGroup(collGroups, arrbase, ch);
      r = ra[0];
      w = ra[1]; 
    }
  } else if (collGroups.length == 2) { 
    // c двумя элементами, разворачивать для поиска в двух вариантах
    const ab = collGroups[0]+'+'+collGroups[1];
    const ba = collGroups[1]+'+'+collGroups[0]; 
    if (found(ab, arrbase)) { // найден, возвращаем его
      r = w = ab;
    } else if (found(ba, arrbase)) {
      r = w = ba;
    } else {
      const ra = summGroup(collGroups, arrbase, ch);
      r = ra[0];
      w = ra[1]; 
    }
  }

  return [r,w];
}

function summGroup(collGroups, arrbase, ch){ 
  // в массиве collGroups может быть один или два элемента
  let r='', w='';
  if (collGroups.length == 1) { // один элемент например 2*x, ранее уже проверен в базе
    // разобрать 2*x на x+x
    const smArr = smashMultToArr(collGroups[0]); // получил массив x, x
    const ra = comMod(smArr, arrbase, ch);
    r = ra[0];
    w = ra[1];
  } else { // два элемента [1, 'x'] уже проверено в двух вариантах ранее
    const ra = comMod(collGroups, arrbase, ch);
    r = ra[0];
    w = ra[1];
  }
  return [r,w];
}

function comMod(arr, arrbase, ch, mode){ 
  // принимает массив [1, 'x'] или ['x', 'x'] и т.д.
  let sum = 0, r='', a='', b='', c='';
  for (let i = 0; i < arr.length; i++) {
    if (!isNaN(Number(arr[i]))) { // это число
      sum += Number(arr[i]);
      a += arr[i] + ' + ';
/*     } else { // это полином, перевести 'x' в 10-ю систему  
      const n = polinomToDecimal(arr[i]);
      a += n + ' + ';
      if (!isNaN(Number(n))) {
        sum += n;
      } */
    }
  }
  a = a.slice(0, -3) + ' = ' + sum;
  if (found(sum, arrbase)) {
    r = sum;
  } else {
    r = sum % ch;
    b = cursor + sum + " mod " + ch + " = " + r;
    if (!found(r, arrbase)) { // не найден
      if (mode == 0) { // полиномиальный 
        const bin = r.toString(2);
        r = bikToPolinom(bin); // binToPolinom
        const pln = bikToPolinom(bin); // binToPolinom
        c = cursor + bin + getIndex(ch) + cursor + pln;
        if (!found(r, arrbase)) { // не найден
          r = c = err;
        }          
      } else {
        r = c = err; 
      } 
    }
  }
  return [r,a+b+c];
}

//#endregion summ

//#region mult

function multiplication(arrbase, a, b, g, ch, mode) {
  let r='', w='';
  
  //console.log('a, b:', a, b);

  if(mode == 0 || mode == 1) { 
    if (a==0 || b==0) return [0,0];
    if (a==1) return [b,b];
    if (b==1) return [a,a];
  }

  if(mode == 0) { // Полиномиальный режим  
    const ra = multAllPolinom(a, b, arrbase, g, ch);
    r=ra[0];
    w=ra[1];
   } else if (mode == 1) { // Числовой режим 
    const anum = Number(a);
    const bnum = Number(b);
    if (!isNaN(anum) && !isNaN(bnum)) {
      const ra = multNumbers(anum, bnum, arrbase, ch);
      r=ra[0];
      w=ra[1];
    }
  } else if (mode == 2) { // Двоичный режим (bin или bik)
    if(checkBik(g)) { // bik
      const ra = multBikMode(a, b, ch, g, arrbase); 
      r=ra[0];
      w=ra[1];
    } else { // bin 
      const ra = multBinMode(a, b, arrbase, ch, g);
      r=ra[0];
      w=ra[1];    
    }
  }
  return [r,w];
}

function multAllPolinom(a, b, arrbase, g, ch) {
  let c='', d='', e='', f='', h='';
  //console.log('a,b:', a, b); // a, b: 2 x^2+3*x+2
  
  if (a.length==1 && b.length==1) {
  // разобрать простые комбинации, типа (2)*(x) или (x)*(2) = (2*x)  
    const ab = a+"*"+b;
    const ba = b+"*"+a;
    if (found(ab, arrbase)) {
      return [ab,ab];
    } else if (found(ba, arrbase)) {
      return [ba,ba];
    }
  }
  
  //if(a=='x' && b=='x') { //}
  //console.log('0:', a, b);
  const expbrac = expandbracketsmult(a, b, ch);
  c = expbrac;
  //console.log('1:', expbrac);
  if (found(expbrac, arrbase)) { 
    return [expbrac,c];
  }
  
  if (!expbrac.includes('x') && (expbrac.includes('+') || expbrac.includes('*'))) {
    //если: 1+2*2 надо сначала умножить потом сложить, как отыскать такие полиномы? нет 'x', есть '+' or '*'
    const calc = calculate(expbrac);
    d = cursor + calc;
    //console.log('calc:', calc);
    if (found(calc, arrbase)) {
      return [calc,c+d];  
    } else {
      const redarr = reduceByCharacteristic(calc.toString().split(), ch);
      //console.log('redarr:', redarr); 
      const aftereduce = redarr.join('+');
      //if (redarr.toString() !== calc.toString()){//показать e, если было сокращение
      if (redarr != calc){
        e = cursor + aftereduce;
      }
      if (found(aftereduce, arrbase)) {
        return [aftereduce,c+d+e];
      } 
    } 
  } 

  if (expbrac.length == 1 && !isNaN(Number(expbrac))) {
    // если один символ и number то передать в comAlg
    const ra = comAlg(expbrac, arrbase, ch);
    return [ra[0],c+ra[1]];
  } 
  
  if (expbrac.length > 1) {
    const redarr = reduceByCharacteristic(expbrac.split(), ch);
    //console.log('2:', redarr);
    if (found(redarr.join('+'), arrbase)) {
      return [redarr.join('+'), c + cursor + redarr.join('+')];
    } else {
      const expand  = expandbracketsum(redarr.join('+'), ch);
      const expandf = expand.sort().reverse().join('+');
      //console.log('expandf:', expandf);
      if(redarr > expandf) {// todoview
        d = cursor + expandf;
      }
      //console.log('3:', expandf);
      if (found(expandf, arrbase)) {
        return [expandf,c+d];
      } else {
        const bin = polinomToBin(expandf);
        //console.log('bin:', bin);
        e = cursor + bin; // + getIndex(ch);      
        if (found(bin, arrbase)) {
          return [expandf,c+d+e];
        } else {
          const xor = xoro(bin.toString(), g.toString());
          //console.log('g:', g);
          //f = cursor + bin+ getIndex(ch) + " xoro "+ g + getIndex(ch) + " = "+ xor;
          f = cursor + bin + " xoro "+ g + " = "+ xor;
          if (found(xor, arrbase)) {
            return [xor,c+d+e+f];
          } else {
            const p = binToPolinom(xor.toString());
            h = cursor + p;
            if (found(p, arrbase)) {
              return [p,c+d+e+f+h];
            }
          }
        }
      }
    }
  }
  
  //}//
  return [err,c+d+e+f+h+err];
}

function expandbracketsmult(a, b, ch) { 
  // Раскрыть скобки c сокращением
  //console.log('1:', a, b);
  //1. разложить a, b на операнды по '+'' в массивы
  const aar = a.split('+');
  const bar = b.split('+');

  let c = '';
  // умножить каждый операнд bar на каждый операнд aar 
  for (let i = 0; i < bar.length; i++) {
    for (let j = 0; j < aar.length; j++) {
      c = c + bar[i] + '*' + aar[j] + '+';
    }
  }
  // получилось разложение: x*2*x+1*2*x
  c = c.slice(0, -1);
  //console.log('2:', c);

  //'c' в массив по '+''
  let ar = c.split('+');
  //console.log('3:', ar);

  let r = '';
  // умножить каждую пару операндов
  for (let i = 0; i < ar.length; i++) {
    let mult = multel(ar[i]);
    r = r + mult + "+";
  }
  r=r.slice(0, -1);
  //console.log('4:', r);

  // сократить результат по характеристике ch
  r = spreadArrToOnePlusX(r.split()); // разобрать полином на мелочь: 1 и x
  //console.log('7:', r);
  r = reduceArrDoublK(r, ch); // удалить дубли по 1 и x количеством ch
  //console.log('8:', r);
  r = collectGroups(r, ch); // собрать в группу
  //console.log('9:', r);

  if(!r.length) {
    return 0;
  } else {
    return r.join('+');
  }
}

function multNumbers(a, b, arrbase, ch){ 
  const mult = a * b;
  const ra = comAlg(mult, arrbase, ch);
  return [ra[0],mult + ra[1]];
}

function multBikMode(a, b, ch, g, arrbase){ 
  // Умножение Двоичный c коэффициентом
  let r = err, c='', d='', e='';
  
  const ad = parseInt(a, Number(ch)); // 010724 заменяю transBinToDec -> parseInt
  const bd = parseInt(b, Number(ch));
  //console.log('ad, bd', ad, bd);
  
  if (ad==0 || bd==0) {
    const nol = addLeadingZero('0', a.length);
    return [nol,nol];
  }
  if (ad==1) return [b,b];
  if (bd==1) return [a,a];

  //if(a=='03' && b=='14') { //
  const mul = Number(a) * Number(b);
  //console.log('ad, bd', ad, bd);
  //console.log('mul', mul);
  //const sz = addLeadingZero(mul.toString(), a.length);
  //console.log('sz', sz);
  //if (found(sz, arrbase)) { //050724 нельзя просто умножать, например 03*14
    //return [sz,sz];
  //} else { // сокращать по mod только если каждый операнд меньше ch
    if((Number(ad) < Number(ch)) && (Number(bd) < Number(ch))) {
      const mod = mul % ch;
      const mz = addLeadingZero(mod.toString(), a.length);
      //console.log('mz', mz);
      if (found(mz, arrbase)) {
        c = mul + cursor;
        r=mz;
      }
    } else { // 4. трансформировать каждый операнд в полином
      const ap = bikToPolinom(a, Number(ch));
      const bp = bikToPolinom(b, Number(ch));
      //console.log('p', ap, bp);
      const exp = expandbracketsmulNoReduce(ap, bp);
      c = exp + cursor;
      //console.log('exp', exp);
      let arr = spreadArrToOnePlusX(exp.split());// разобрать полином на мелочь: 1 и x
      arr = reduceArrDoublK(arr, ch);// удалить дубли по 1 и x количеством ch
      //console.log('arr', arr);
      if(!arr.length) { // характеристика "убила" все элементы
        const nol = addLeadingZero('0', a.length);
        r=nol;
      } else if (arr.length == 1) { // в массиве 1 элемент
        const bin = polinomToBin(arr[0]);
        //console.log('bin', bin);
        const bz = addLeadingZero(bin.toString(), a.length);
        //console.log('bz:', bz);
        if (found(bz, arrbase)) {// проверить на нахождение в базе
          r=bz;
        } else { // применить xori
          //console.log('bz,g,ch', bz,g,ch);
          const xor = xori(bz.toString(),g.toString(),Number(ch));
          //console.log('xor', xor);
          if (found(xor, arrbase)) {
            r=xor;
            d = bz + ' xori '+ g + ' = ';
          } else return [r,r];
        } //
      } else { // осталось больше 1 элемента
        //console.log('arr1:', arr); // 
        arr = collectGroups(arr, ch); // собрать в группу
        //console.log('arr2:', arr); 
        if (exp > arr) {
          d = arr.join('+') + cursor;
          //console.log('d:', d); 
        }
        //console.log('arr3:', arr.join('+'));
        const rb = polinomKtoBin(arr.join('+'));
        //console.log('rb:', rb);
        if (found(rb, arrbase)) {
          r=addLeadingZero(rb.toString(), a.length);
        } else {
          const tr = transPolinomK(arr, ch, g);
          //console.log('tr:', tr); 
          if (found(tr[0], arrbase)) {
            r=addLeadingZero(tr[0].toString(), a.length);
            e = tr[1];
          }
        }
      }
    }  
  //}
  //}// 
  return [r,c+d+e+r];
}

function multBinMode(a, b, arrbase, ch, g){
  //if(a=='010' && b=='101') { 
  //console.log('a,b', a, b);
  // перевожу каждый операнд в 10-ю систему
  const ad = parseInt(a, 2);
  const bd = parseInt(b, 2);
  //console.log('ad, bd', ad, bd);

  if (a==0 || b==0) {
    const nol = addLeadingZero('0', a.length);
    return [nol,nol];
  }
  if (a==1) {
    const br = addLeadingZero(b, a.length);
    return [br,br];
  }
  if (b==1) {
    const ar = addLeadingZero(a, a.length);
    return [ar,ar];
  }

  const arr = removeLeadingZeroArr(arrbase);

  if(g>0) { //если g заполнен, то через полиномы
    //console.log('g', g);
    const ra = multWithPolinom(a, b, ch, arrbase, g);
    return [ra[0],ra[1]];
  } else {
    const mult = ad * bd; // умножаю в 10-й системе
    //console.log('mult', mult); 
    const bin = mult.toString(2); // результат перевожу в бинарный вид
    //console.log('bin, mult', bin, mult);

    if (found(bin, arr)) {
      return [addLeadingZero(bin, a.length),addLeadingZero(bin, a.length)];
    //} else if(found(mult, arr)) { убрал из-за ошибок в gf_7_1_bin
      //return [addLeadingZero(mult.toString(), a.length),addLeadingZero(mult.toString(), a.length)];
    } else {
      const ra = useMod(mult, bin, ch, arr, a.length);
      return [ra[0],ra[1]];
    }
  }  
  //} return [err,err];
}

function multWithPolinom(a, b, ch, arrbase, g){
  let r='', c='', d='', e='', f=''; //
  
  // Перевести каждый операнд в полином
  const ap = binToPolinom(removeLeadingZeroBin(a));
  const bp = binToPolinom(removeLeadingZeroBin(b));
  //console.log('ap, bp', ap, bp);
  c = "("+ap+") * ("+bp+")" + cursor;

  // Раскрыть скобки без сокращения
  const exp = expandbracketsmulNoReduce(ap, bp); 
  // получил строку в полиномах типа: x^2 или биноме ньютона x^2+x+x+1

  // Сократить полином по характеристике ch 
  let p = spreadArrToOnePlusX(exp.split());// разобрать полином на мелочь: 1 и x
  p = reduceArrDoublK(p, ch);// удалить дубли по 1 и x количеством ch
  p = collectGroups(p, ch);// собрать в группу
  //console.log('p', p);
  if (exp > p) {
    d = cursor + p.sort().reverse().join('+');
  }
  
  // Преобразовать в бинарный вид
  const bin = polinomToBin(p.sort().reverse().join('+'));
  const bino = addLeadingZero(bin.toString(), a.length);
  e = cursor + bino;
  //console.log('bino', bino);
  if (found(bino, arrbase)) {
    r = bino;
  } else {
    const x = xoro(bin.toString(), g.toString());
    const xo = addLeadingZero(x.toString(), a.length);
    f = cursor + bin + ' xoro ' + g + ' = ' + xo;
    //console.log('a.length', a.length, xo);
    if (found(xo, arrbase)) {
      r = xo;
    } else {
      r = err;
    }
  }
  return [r,c+exp+d+e+f];
}

//#endregion mult function

export { 
  multiplication,
  summation,
  addLeadingZero,
  bikToPolinom,
  binToPolinom,
  calcdeg,
  calculate,
  checkBik,
  collectGroups,
  combineWithRepetitions,
  containsSymbol,
  defineDegree,
  expandbracketsum,
  expandbracketsmulNoReduce,
  factorial,
  found,
  getIndex,
  isArraysEqual,
  isPrime,
  maxLengthStr,
  multel,
  numberMultPolinom,
  noContainSymbolS,
  permute,
  permuteUnique,
  permutations,
  permutateWithRepetitions,
  phi,
  polinomToDec,
  polinomToBin,
  polinomToBinSimple,
  polinomKtoBin,
  polinomKtoBinSimple, 
  reduceArrDoublK,
  reduceByCharacteristic,
  reduceElArrK,
  removeLeadingZeroBin,
  removeLeadingZeroStr,
  removeLeadingZeroArr,
  smashMultToArr,
  spreadArrToOnePlusX,
  transPolinomK,
  xori,
  xoro
}



