<template>
  <v-content>
    <v-chip-group>
      &nbsp;&nbsp;
      <v-text-field
        label="GF(p^n)"
        hint="GF(p^n) или GF(q)"
        v-model="state.qgf" 
        :error-messages="v$.qgf.$errors.map(e => e.$message)"
        @blur="v$.qgf.$touch"
        append-inner-icon="mdi-select"
        @click:append-inner="genvalid"
        @keyup.enter="genvalid"
      ></v-text-field>
      &nbsp;&nbsp;
      <v-text-field
        label="Порождающие полиномы"
        v-model="state.gg"
        readonly
      ></v-text-field>     
      &nbsp;&nbsp;
      <div class="modelselect">
        <input type="checkbox" id="summ" value={{state.nameWithTables}} v-model="checkedWithTables" />
        <label for="summ">{{state.nameWithTables}}</label>                 
      </div>
      &nbsp;&nbsp;
      <v-btn @click="sendToGF">send To GF</v-btn>
      &nbsp;&nbsp;
    </v-chip-group>

    <div id="scroll-container">
      <div id="large-container">
        <div id="container"></div>
      </div>
    </div>
  </v-content>
</template>

<style>
  .modelselect {
    font-family: sans-serif;
    border: 0.1px solid #fff;
    border-radius: 2px;
    padding: 10px;
    user-select: none;
    overflow-x: auto;
  }

  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f0f0f0;
    background: #fcfcfc;
    height: 100%;
    overflow: auto;
  }

  #large-container {
    width: 3000px;
    height: 3000px;
    overflow: hidden;
  }

  #scroll-container {
    width: calc(100% - 22px);
    height: calc(100vh - 22px);
    overflow: auto;
    margin: 10px;
    border: 1px solid grey;
  }  
</style>

<script>
//#region import
import { 
  addLeadingZero,
  permutateWithRepetitions,
  bikToPolinom,
  found,
  isArraysEqual,
  multiplication,
  polinomKtoBin,
  polinomKtoBinSimple,
  phi,
  summation,
  xori
 } from '../utils/common.js';
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators'; //
import Konva from 'konva';
import { mapActions } from "vuex";
var width = window.innerWidth;
var height = window.innerHeight;
var PADDING = 500;
var layer = '';
//#endregion import

export default {
  data() {
    return { 
      checkedWithTables: false, //true
      configKonva: {
        width: width,
        height: height
      },
      arbase: [],
      arprim: [],
      argen: [],
    };
  },

  mounted() {
  //#region Konva
    var stage = new Konva.Stage({
      container: 'container',
      width: window.innerWidth + PADDING * 2,
      height: window.innerHeight + PADDING * 2,//2
      draggable: true,
    });

    layer = new Konva.Layer();
    stage.add(layer);

    var scrollContainer = document.getElementById('scroll-container');

    function repositionStage() {
      var dx = scrollContainer.scrollLeft - PADDING;
      var dy = scrollContainer.scrollTop - PADDING;
      stage.container().style.transform =
        'translate(' + dx + 'px, ' + dy + 'px)';
      stage.x(-dx);
      stage.y(-dy);
    }

    scrollContainer.addEventListener('scroll', repositionStage);
    repositionStage();
  //#endregion Konva
    this.state.qgf = '2^3';
    this.generation();
    //console.log('->:', this.xoo('10', '1121', 3));
  },

  methods: {

    generation(){
      // очистить в начале
      layer.removeChildren();
      this.argen.length=0;
      this.arprim.length=0;
      this.arbase.length=0;
      this.state.gg = '';

      let p=1, n=1, k=1;
      if(this.state.qgf.includes('^')) {
        const arqgf= this.state.qgf.split('^');
        p = Number(arqgf[0]); 
        n = Number(arqgf[1]);
        k = Math.pow(p, n);
      } else {
        k = p = Number(this.state.qgf);
      }
      //console.log('k', k);

      const q = (k-1).toString(p).length; // кол-во знаков: '00' или '000'
      const nk = Number(k.toString().length);

      // генерация массива элементов на основе p^n
      for(let i=0; i<k; i++) {
        if(i<p) {
          this.arbase.push(addLeadingZero(i.toString(), q));
        } else {
          this.arbase.push(addLeadingZero(i.toString(p), q));
        }
      }
      
      // Определить режим
      if (this.arbase[0].length > 1 && this.arbase[0].includes('00')) { 
        this.state.mode = 2; // Двоичный (bin или bik)
      } else {
        this.state.mode = 1; // Числовой
      }

      const deltay = 30, x=20;
      let y=50;
      this.renderArbase(x, y, 20, k); // 1 шаг
      const ar = this.defineCandidate(p,n,nk,k);
      y+=75;
      this.renderCandidate(x, y, 20, ar[0], ar[1], k); // 2 шаг
      y+=75;
      this.renderTest(x, y, 20, deltay, k, nk, p, ar[2]); // 3 шаг
      y+=(ar[2].length) * deltay + 40;
      this.renderTotient(x, y, 20, k, n); // 4 шаг
      y+=100;
      this.state.gg = this.arprim; // заполнить gg сверху
      if(this.arprim.length > 0 && this.checkedWithTables) { // продолжаем если есть кандидаты и стоит флаг "с таблицами"
        this.renderTables(x, y, 20, deltay, p); // 5 шаг
        this.state.gg = this.argen; // заполнить gg сверху
      }
      
      this.renderParam(5, 10, 24);  
      //console.log('->', this.checkedWithTables);
    },

    renderTablePol(x, y, fontSize, gp, p){
      const g = polinomKtoBin(gp.toString());
      const errsumm = this.testSumm(g, p);
      const errmult = this.testMult(g, p);
      const errtotal = errsumm + errmult;
      if(errtotal == 0){
        this.argen.push(gp);
      }
      const param = g + ': ' + gp + ', Ошибки: в суммировании: ' + errsumm + ', в умножении: ' + errmult;
      layer.add(this.addText(x, y, param, fontSize));
    },

    renderTables(x, y, fontSize, deltay, p){
      const param = '5. Тестирование примитивных полиномов таблицами "+" и "*"';
      layer.add(this.addText(x, y, param, fontSize));
      let dy=30;
      for(let i=0; i < this.arprim.length; i++){
        this.renderTablePol(x+30, y+dy, fontSize, this.arprim[i], p);
        dy+=deltay;
      }
    },

    testSumm(g, p){
      //console.log('g:', g, p);
      const n = this.arbase.length + 1;

      let errorline_sum = [], arr_sum = new Array(n);

      for (let i = 0; i < arr_sum.length; i++) { 
        let arr_line_sum = []; //сюда записываются значения элементов по столбцам
        arr_sum[i] = new Array(n);
        for (let j = 0; j < n; j++) {
          //Заполнить горизонталь
          if (j>0 && i>0) {///
            //console.log('i:', i);
            let sum = summation(this.arbase, this.arbase[i-1], this.arbase[j-1], g, p, this.state.mode);
            arr_line_sum[j-1] = sum; //вести учет введенных элементов строки
            arr_line_sum[this.arbase.length] = this.arbase[i-1]; //записать номер строки        
            //arr_sum[i][j] = sum;
/*           } else {
            arr_sum[i][j] = this.arbase[i-1]; */
          }
          //Заполнить вертикаль
/*           if (j==0) {
            arr_sum[0][i] = this.arbase[i-1]; 
          } */
        }
        // Поймать неправильный столбец в сложении, с дублями элементов 
        const duplicates_sum = arr_line_sum.filter((e, i, a) =>  
          (a.indexOf(e) !== i) && (i !== arr_line_sum.length - 1));
        if (duplicates_sum.length > 0) {  
          errorline_sum.push(arr_line_sum[arr_line_sum.length-1]);
        }
      }
      
      return errorline_sum.length;
    },

    testMult(g, p){
      //console.log('g:', g, p);
      const n = this.arbase.length + 1;
      let errorline_mult = [], arr_mult = new Array(n);

      for (let i = 0; i < arr_mult.length; i++) { 
        let arr_line_mult = []; //сюда записываются значения элементов по столбцам
        arr_mult[i] = new Array(n);
        for (let j = 0; j < n; j++) {
          //Заполнить горизонталь
          if (j>0 && i>0) {
            //console.log('i:', i);
            let mult = multiplication(this.arbase, this.arbase[i-1], this.arbase[j-1], g, p, this.state.mode);
            if (i != 1 ) { // избавиться от 0
              arr_line_mult[j-1] = mult; //вести учет введенных элементов строки
            }
            arr_line_mult[this.arbase.length] = this.arbase[i-1]; //записать номер строки
            //arr_mult[i][j] = mult;
/*           } else {
            arr_mult[i][j] = this.arbase[i-1]; */
          }
          //Заполнить вертикаль
/*           if (j==0) {
            arr_mult[0][i] = this.arbase[i-1]; 
          } */
        }
        // Поймать неправильный столбец в умножении, с дублями элементов
        const duplicates_mult = arr_line_mult.filter((e, i, a) => 
          (a.indexOf(e) !== i) && (i !== arr_line_mult.length - 1));
        if (duplicates_mult.length > 0) {
          errorline_mult.push(arr_line_mult[arr_line_mult.length-1]);
        }
      }
      //console.log('errorline_mult.length:', errorline_mult.length);
      return errorline_mult.length; // 
    },

    renderCandidate(x, y, fontSize, arcand, totaln, k){
      const param = '2. Кандидаты в примитивные полиномы: '+ arcand.length + ' из ' + totaln + ', сразу рассчитаем x^'+(k-1);
      layer.add(this.addText(x, y, param, fontSize));
      layer.add(this.addText(x+30, y+30, arcand, fontSize));
    },

    defineCandidate(p,n,nk,k) {
      const xk = 'x^'+(k-1);
      let artest=[];
      const ra = this.permute(p,n);
      const ar = ra[0];
      //console.log('kol', ra[1]);
      let r=[];
      for(let i=0; i<ar.length; i++){
        const xki = this.getXk(xk, ar[i].toString(), p);
        if(xki == 1) artest.push(ar[i]);
        r.push(ar[i]);
      } 
      //console.log('r', r.length);
      return [r, ra[1], artest];
    },

    renderTest(x, y, fontSize, deltay, k, nk, p, artest){
      const param = '3. Тестирование кандидатов (у которых x^'+(k-1) + ' = 1), '  + artest.length + ' шт.: ' + artest;
      layer.add(this.addText(x, y, param, fontSize));
      let dy=30;
      for(let i=0; i<artest.length; i++) {
        this.renderTestPolinom(x+30, y+dy, fontSize, artest[i], k, nk, p);
        dy+=deltay;
      }
    }, 

    renderTestPolinom(x, y, fontSize, g, k, nk, p){
      // вычислить степень от каждого k, и взять xori 
      let arcan=[];
      for(let i=1; i<k; i++) {
        const ra = this.calcDeg(i, g, p, nk);
        arcan.push(ra[3]);
      }
      arcan.push(this.arbase[0]); // добавить нулевой
      arcan.sort(); 
      //console.log('arcan:', arcan);

      //сравнить базовый и кандидат
      const isEqual = isArraysEqual(this.arbase, arcan);
      const param = g + ': ' + bikToPolinom(g, p) + '  ->  ' + (isEqual ? 'Успешно' : 'Неуспешно');
      if(isEqual){
        this.arprim.push(bikToPolinom(g,p));
      }
      layer.add(this.addText(x, y, param, fontSize));

    },

    renderTotient(x, y, fontSize, k, n){
      const tot = (phi(k-1))/n;
      const param1 = '4. Количество примитивных полиномов определяется функцией Эйлера:';
      layer.add(this.addText(x, y, param1, fontSize));
      const param2 = 'ϕ(p^n-1)/n = ϕ('+(k-1)+')/'+n+' = ' + tot; // ϕ = U+03D5
      layer.add(this.addText(x+30, y+30, param2, fontSize));
      const param3 = 'Определены: ' + this.arprim.length + ' шт.:  ' + this.arprim.join(';  ');
      layer.add(this.addText(x+30, y+60, param3, fontSize));
    },

    renderArbase(x, y, fontSize, k){
      const param = '1. Сгенерировано базовое множество: ' + k + ' эл.';
      layer.add(this.addText(x, y, param, fontSize));
      layer.add(this.addText(x+30, y+30, this.arbase, fontSize));
    },

    renderParam(x, y, fontSize){
      let param = 'G= ';
      if(this.checkedWithTables){
        param += this.argen;
      } else {
        param += this.arprim;
      }
      layer.add(this.addText(x, y, 'GF(' + this.state.qgf + '); ' + param, fontSize));
    },

//#region     
    calcDeg(k, g, p, nk){
      const polinom = 'x^'+k;
      const bin = polinomKtoBinSimple(polinom.toString());
      let r = addLeadingZero(bin.toString(), this.arbase[0].length);
      if (!found(r, this.arbase)) {
        r = xori(r.toString(), g.toString(), Number(p));
        r = addLeadingZero(r.toString(), this.arbase[0].length)       
      }
      const b = bikToPolinom(r);
      return [addLeadingZero(k.toString(), nk), polinom, b, r];
    },

    getXk(xk, g, p){
      const bin = polinomKtoBinSimple(xk);
      const xr = xori(bin.toString(), g.toString(), p)
      return xr;
    },

    sendToGF(){
      if(this.arprim) {
        this.register({ 
          g: this.arprim[0],
          q: this.state.qgf,
          a: this.arbase
        });
        this.$router.push('/'); //открыть GF
      }
    },

    draw(x, y, num, line, data, deltax, deltay, fill, fontSize) {
      layer.add(this.addText(((deltax*(num-1))-(deltax/(deltax/4))+x), 
        ((deltay*line)-(deltay/(deltay/5))+y), data, fontSize, 'Arial', fill));
    },

    addText(x, y, txt, fontSize, fontFamily, fill) {
      return new Konva.Text({
        x: x,
        y: y,
        text: txt,
        fontSize: fontSize,
        fontFamily: fontFamily, //'Calibri' 'Georgia' 'Arial'
        fill: fill, // 'red' 'black'
      });
    },

    permute(p,n){
      // составить массив возможных чисел, зависит от p
      let ar=[], i=0;
      while(i<p) { ar.push(i); i++; }

      const kol = permutateWithRepetitions(ar, n+1).length;

      let perm = permutateWithRepetitions(ar, n+1)
        .reduce((acc, item) => { 
          // начинается всегда на 1 и не может оканчиваться на 0
          if(item[0] == 1 && item[n] != 0) {
            acc.push(item);
          }
          return acc;      
      },[]);

      return [perm.map((subjects) => subjects.join('')), kol];
    },

    genvalid() {
      if (!this.v$.$invalid ) {
        this.generation();
      }
    },

    ...mapActions(["register"]),

//#endregion 
  },

  setup () {
    const numregex= helpers.regex(/^(\d*\^\d*|\d)*$/)
    const state = reactive({ 
      qgf: '', 
      gg: '',
      mode:  '',
      nameWithTables: 'с таблицами',
    })
    const rules = {
      qgf: { required, numregex: helpers.withMessage('число^число или число', numregex) },
    }
    const v$ = useVuelidate(rules, state)
    return { state, v$ }
  },

}
</script>