import { createApp } from 'vue'
import Vuex from 'vuex'
const app = createApp()
app.use(Vuex)

const state = () => ({
    gen : null,
})

const getters = {
    gen : state => state.gen,
}

const mutations = {    
    setGen: (state, gen) => {
        state.gen = gen
    },
}

const actions = {
    register({commit}, gen){
        return new Promise(() => {
            commit('setGen', gen);
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
