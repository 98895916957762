<template>
  <div id="app">
    <LayoutView />
  </div>
</template>

<script>
import LayoutView from './components/LayoutView.vue';
export default {
  name: 'App',
  components: {
    LayoutView
  }
}
</script>